import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import Blockquote from './src/components/blog/blockquote';

require('./src/style/custom.css');
// custom typefaces'
require('prismjs/themes/prism-coy.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');

// in gastby-browser.js
// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {

//   const { pathname } = location

//   if (!pathname.includes("page") && !location.hash)  {
//     window.scrollTo(0, 0)
//   }

//   return false
// }

// const React = require('react');
// const MDXProvider = require('@mdx-js/react');

//Custom Blockquote
const components = {
  blockquote: (props) => <Blockquote {...props} />,
};
export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
