import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

type BlockquoteProps = {};

const BlockquoteWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 20px 50px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  @media (max-width: 575px) {
    padding: 20px 15px;
  }
`;

const TopArea = styled.div`
  display: flex;
  min-height: 120px;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

const AuthorThumb = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  align-self: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Quote = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  padding-left: 40px;
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 575px) {
    margin-top: 20px;
    margin-left: 0;
    padding-left: 20px;
  }

  p {
    font-size: 24px !important;
    line-height: 1.7em !important;
    margin-bottom: 0 !important;
    @media (max-width: 575px) {
      font-size: 18px !important;
    }
  }
`;

const QuoteIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 100%;
  opacity: 0.06;
  pointer-events: none;
  @media (max-width: 575px) {
    width: 120px;
  }
`;

const BottomArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #00000024;
  margin-right: 30px;
  @media (max-width: 575px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const AuthorName = styled.div`
  font-size: 14px;
  font-style: italic;
  text-align: right;
  line-height: 1.7em;
`;

const Blockquote: React.FunctionComponent<BlockquoteProps> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      thumbnail: file(absolutePath: { regex: "assets/ruben-portrait.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      icon: file(absolutePath: { regex: "assets/quote-mark.png/" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <BlockquoteWrapper {...props}>
      <TopArea>
        <AuthorThumb>
          <Img
            fluid={data.thumbnail.childImageSharp.fluid}
            alt="The Gamedev Guru Thumbnail"
          />
        </AuthorThumb>
        <Quote>
          <QuoteIcon>
            <Img fluid={data.icon.childImageSharp.fluid} alt="Quote Icon" />
          </QuoteIcon>{' '}
          {props.children}
        </Quote>
      </TopArea>

      <BottomArea>
        <Line />
        <AuthorName>
          Rubén <br />
          (The Gamedev Guru)
        </AuthorName>
      </BottomArea>
    </BlockquoteWrapper>
  );
};

export default Blockquote;
